import React from 'react'

const SvgEyeIcon = ({ ...props }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="13" cy="13" r="13" fill="#FF0000" />
            <g clipPath="url(#clip0_41_261905)">
                <path d="M12.9999 7.58325C9.38875 7.58325 6.30486 9.82936 5.05542 12.9999C6.30486 16.1705 9.38875 18.4166 12.9999 18.4166C16.611 18.4166 19.6949 16.1705 20.9443 12.9999C19.6949 9.82936 16.6146 7.58325 12.9999 7.58325ZM12.9999 16.611C11.0065 16.611 9.38875 14.9933 9.38875 12.9999C9.38875 11.0066 11.0065 9.38881 12.9999 9.38881C14.9932 9.38881 16.611 11.0066 16.611 12.9999C16.611 14.9933 14.9932 16.611 12.9999 16.611ZM12.9999 10.8333C11.8046 10.8333 10.8332 11.8046 10.8332 12.9999C10.8332 14.1952 11.8046 15.1666 12.9999 15.1666C14.1951 15.1666 15.1665 14.1952 15.1665 12.9999C15.1665 11.8046 14.1951 10.8333 12.9999 10.8333Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_41_261905">
                    <rect width="17.3333" height="17.3333" fill="white" transform="translate(4.33325 4.33325)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SvgEyeIcon