const amplifyConfig = {
    Auth: {
        region: 'eu-central-1',
        identityPoolRegion: 'eu-central-1',
        userPoolId: 'eu-central-1_qjH3uArwD',
        userPoolWebClientId: 'guqq69uoiig1hgrbhig77poic',
    },
    Analytics: {
        disabled: true,
    },
}

export default amplifyConfig
